
import { defineComponent, ref, onMounted, computed, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import AppService from "@/core/services/AppService";
import { News } from "@/store/model/News";
import { AccountData } from "@/store/modules/AccountsModule";
import { AdminDefault, AdminModel } from "@/store/model";

export default defineComponent({
  name: "add-account",
  components: {},
  setup() {
    const store = useStore();
    const router = useRouter();
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const listDataRoles = ref([]);
    let objectData = ref<AdminModel>(AdminDefault as AdminModel);
    onMounted(async () => {
      await store.dispatch("GET_ROLES_DATA", AppService.convertParams(""));
      listDataRoles.value = store.getters["listDataRoles"];
      setCurrentPageBreadcrumbs("Tạo mới tài khoản", ["Người dùng"]);
      MenuComponent.reinitialization();
    });
    const rules = ref({
      name: [
        {
          required: true,
          message: "Họ tên không được để trống",
          trigger: "blur",
        },
      ],
      email: [
        {
          required: true,
          message: "Email không được để trống ",
          trigger: "blur",
        },
        {
          type: "email",
          message: "Email chưa dúng định dạng",
          trigger: ["blur", "change"],
        },
      ],
      password: [
        {
          required: true,
          message: "Email không được để trống ",
          trigger: "blur",
        },
        {
          min: 6,
          message: "Tối thiểu 6 ký tự",
          trigger: "blur",
        },
      ],
      role: [
        {
          required: true,
          message: "Chọn ít nhất 1 quyền ",
          trigger: "blur",
        },
      ],
    });
    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          // loading.value = true;
          store
            .dispatch("ACTION_ADD_ACCOUNT", objectData.value)
            .then((data) => {
              Swal.fire({
                text: "Tạo thành công",
                icon: "success",
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: "Danh sách",
                cancelButtonText: "Tiếp tục chỉnh sửa",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-success",
                  cancelButton: "btn fw-bold btn-light-primary",
                },
              }).then(async (result) => {
                // Go to page after successfully login
                if (result.isConfirmed) {
                  await router.push({ name: "accounts" });
                } else {
                  //console.log("data",data);
                  await router.push({
                    name: "accounts-edit",
                    params: { id: data.data._id },
                  });
                }
              });
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getErrors,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Thử lại!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
        } else {
          return false;
        }
      });
    };
    return {
      rules,
      submit,
      loading,
      objectData,
      listDataRoles,
      formRef,
    };
  },
});
